<template>
  <div class="dropdown-PDF">
    <v-menu
        open-on-hover
        top
        offset-y
        close-on-click
        elevation="0"

    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="save"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            min-width="130"
            min-height="40"
            class="white--text"
            :loading="loadingAndDisabled"
            :disabled="loadingAndDisabled"
        >
          <v-icon left size="26">mdi-file-pdf-box</v-icon>
          {{ language.componentLabel.labelReportType }}
        </v-btn>

      </template>
      <v-list>
        <v-list-item
            @click.stop="openPDF('PDFSimple')"
        >
          <v-list-item-title>{{ language.componentLabel.labelOverviewType }}</v-list-item-title>
        </v-list-item>
        <v-list-item
            @click.stop="openPDF('PDF')"
        >
          <v-list-item-title>{{ language.componentLabel.labelDetailedType }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import {checkLoginAndRedirect} from "@/js/helper.js";

export default {
  props: ["buttonAttrs"],
  components: {},
  data: function () {
    return {
      doHide: false,
    };
  },
  computed: {
    loadingAndDisabled() {
      return this.doHide;
    },
  },
  methods: {
    openPDF: function (type) {
      this.getAccessToken().then((accessToken) => {
        let URL = `serve.php?f=testing&f2=testInfo&function=${type}&n_id=${this.buttonAttrs['n_id']}&n_id_key=${this.buttonAttrs['n_id_key']}&accessToken=${accessToken}`;
        checkLoginAndRedirect(URL, this);
      });
      this.doHide = true;
      this.startDownloadChecker(120);
    },
    startDownloadChecker(timeout) {
      var cookieName = "DownloadCompleteChecker";
      var downloadTimerAttempts = timeout;    // seconds
      this.setCookie(cookieName, 0, downloadTimerAttempts);
      var caller = this;
      // set timer to check for cookie every second
      const downloadTimer = window.setInterval(function () {
        var cookie = caller.getCookie(cookieName);
//console.log("DTA"+downloadTimerAttempts+"----"+cookie);
        // if cookie doesn't exist, or attempts have expired, re-enable form
        if ((typeof cookie === 'undefined') || (downloadTimerAttempts == 0)) {
          caller.doHide = false;
          // $("#" + imageId).hide();
          // console.log('---TO-->'+timeout);
          window.clearInterval(downloadTimer);
          caller.expireCookie(cookieName);
        }
        downloadTimerAttempts--;
      }, 2000);
    },
    setCookie(name, value, expiresInSeconds) {
      var exdate = new Date();
      exdate.setTime(exdate.getTime() + expiresInSeconds * 1000);
      var c_value = escape(value) + ((expiresInSeconds == null) ? "" : "; expires=" + exdate.toUTCString());
      document.cookie = name + "=" + c_value + '; path=/';
    },
    getCookie(name) {
      var parts = document.cookie.split(name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    },
    expireCookie(name) {
      document.cookie = encodeURIComponent(name) + "=; path=/; expires=" + new Date(0).toUTCString();
    }
  },


}
</script>
<style scoped>
.backgroundBlur {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: hsl(0deg 0% 100% / 70%);
  height: 100%;
  width: 100%;
}

/* .loaderImg{
   width: 45px;
   position: fixed;
   top: 50%;
   left: 50%;
   z-index: 999999;
 }*/
.loaderImg {
  width: 32px;
  position: absolute;
  z-index: 999999;
  bottom: 7px;
}
</style>